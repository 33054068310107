import React from "react";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import Intro from "../components/safety-using/Intro";
import Safest from "../components/safety-using/Safest";
import Software from "../components/safety-using/Software";
import "../components/safety-using/index.scss";

const PgSU = () => {
  return (
    <Layout customClass="PgSU">
      <Seo
        title="Using AMZScout is 100% safe | AMZScout"
        description=""
        page="safety-using"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <Intro />
      <Safest />
      <Software />
    </Layout>
  );
};

export default PgSU;
