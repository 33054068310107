import imgSVG from "../../../assets/images/vector/pages/safety-using/Safest/PgSU-Safest-img.svg"

const PG_SU_SAFEST_CONTENT = {
  fullText: [
    "Amazon research software because it doesn’t ask for your Amazon Seller Account credentials.",
    "This means AMZScout has no access to your product and financial data. You can be 100% sure that AMZScout can’t share or lose your data and no one can view which products you sell and the profits you earn.",
    "Other tools like JS or H10 are sometimes accused by Amazon sellers of leaking the sellers' data because they access your Amazon Seller Account, read and store your private data and use it in their algorithms.",
  ],
  imgSVG,
}
export default PG_SU_SAFEST_CONTENT
