import React from "react";
import shieldSVG from "../../../assets/images/vector/pages/safety-using/Intro/PgSU-Intro-shield.svg";
import logoSVG from "../../../assets/images/vector/pages/safety-using/Intro/PgSU-Intro-logo.svg";
import "./index.scss";

const Intro = () => {
  return (
    <section className="PgSU-Intro">
      <div className="container">
        <div className="PgSU-Intro__content">
          <img className="PgSU-Intro__shield" src={shieldSVG} alt="img" />
          <img className="PgSU-Intro__logo" src={logoSVG} alt="img" />
          <h1 className="PgSU-Intro__title">
            Why is using AMZScout <span>100% safe?</span>
          </h1>
        </div>
      </div>
    </section>
  );
};

export default Intro;
