import React from "react";
import PG_SU_SAFEST_CONTENT from "./index.content";
import "./index.scss";

const Safest = () => {
  const { imgSVG, fullText } = PG_SU_SAFEST_CONTENT;

  return (
    <section className="PgSU-Safest">
      <div className="container">
        <div className="PgSU-Safest__content">
          <img className="PgSU-Safest__img" src={imgSVG} alt="img" />
          <div className="PgSU-Safest__info PgSU-Safest-info">
            <h2 className="PgSU-Safest__title">AMZScout is the safest</h2>
            {fullText.map((text, i) => (
              <p key={i} className="PgSU-Safest-info__text">
                {text}
              </p>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Safest;
