import imgSVG from "../../../assets/images/vector/pages/safety-using/Software/PgSU-Software-img.svg"

const PG_SU_SOFTWARE_CONTENT = {
  fullText: [
    "that never accesses Amazon sellers profiles and data in any way. AMZScout guarantees Amazon Seller Account data safety, and its employees could never use that data to their own advantage.",
    "We always express our concern about what can happen to an IT company when it has access to data about the actual sales and profits of another company, especially if it is an eCommerce store.",
    "We are excited to create exclusive value for Amazon Sellers with our most accurate algorithms and innovative tools and provide 100% safe services for our customers.",
  ],
  imgSVG,
}
export default PG_SU_SOFTWARE_CONTENT
