import React from "react";
import PG_SU_SOFTWARE_CONTENT from "./index.content";
import "./index.scss";

const Software = () => {
  const { imgSVG, fullText } = PG_SU_SOFTWARE_CONTENT;

  return (
    <section className="PgSU-Software">
      <div className="container">
        <div className="PgSU-Software__content">
          <div className="PgSU-Software__info PgSU-Software-info">
            <h2 className="PgSU-Software__title">
              AMZScout is the only software
            </h2>
            {fullText.map((text, i) => (
              <p key={i} className="PgSU-Software-info__text">
                {text}
              </p>
            ))}
          </div>
          <img className="PgSU-Software__img" src={imgSVG} alt="img" />
        </div>
      </div>
    </section>
  );
};

export default Software;
